<template>
  <div style="height: 100vh; padding: 10px">
    <div class="header">
      <van-row gutter="10">
        <van-col @click="onClickBack">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
            style="height: 25px"
          />
          <span style="font-size: 25px; color: white; font-weight: bold"
            >事件</span
          >
        </van-col>
      </van-row>
    </div>
    <card style="margin-top: 30px">
      <template #content>
        <tourInfoTop :tour="onGoingTours[0]"></tourInfoTop>
        <van-divider />
        <van-row style="margin-bottom: 20px;font-size: 0.3rem;">
          <van-col span="12">选择类型</van-col>
          <van-col
            span="12"
            style="text-align: right; font-weight: bold; color: #436ef3"
            @click="showTypes = true"
          >
            {{ type_name }}

            <van-icon name="arrow-down" />
          </van-col>
        </van-row>
        <van-divider />
        <div class="report_area">
          <fieldForm
            :submit="submit"
            :modal="emergency"
            :fieldData="formFields"
            v-on:formUpdate="onFormUpdate"
          ></fieldForm>
        </div>
      </template>
    </card>
    <van-popup
      v-model="showTypes"
      closeable
      close-icon="close"
      :style="{ height: '42%' }"
      position="bottom"
      ><div class="select-container">
        <div class="select-title">选择类型</div>
        <div
          class="select-item"
          v-for="(i, index) in types"
          :key="index"
          @click="onTypeSelect(i)"
        >
          {{ i.type_name }}
        </div>
        <van-empty
          v-if="types.length == 0"
          image="error"
          description="暂无类型可选"
        />
      </div>
    </van-popup>
  </div>
</template>

<script>
import api from "@/services/apis.js";
import { Toast } from "vant";
import { mapGetters } from "vuex";
import card from "@/views/components/Card";
import tourInfoTop from "@/views/components/TourInfoTop";
import fieldForm from "@/views/components/FieldForm";
export default {
  components: { card, tourInfoTop, fieldForm },
  data() {
    return {
      tour: {},
      report_type: 1,
      type_name:"出团事件",
      types: [
        {
          id: 1,
          type_name: "出团事件",
        },
        {
          id: 0,
          type_name: "疫情事件",
        },
        {
          id: 2,
          type_name: "其他",
        },
      ],
      showTypes: false,
      emergency: {
        report_content: "",
        attachment_url: "",
        travel_list_id: 0,
      },
      formFields: [
        {
          title: "事件详情",
          name: "report_content",
          type: "textarea",
          rule: [{ required: true, message: "" }],
          inline: false,
          show_word_limit: true,
          rows: 3,
          max_length: 100,
        },
        {
          title: "附件",
          name: "attachment_url",
          type: "file",
          rule: [],
          max_count: 1,
          inline: false,
          compressed: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("travel_list", ["onGoingTours"]),
  },
  created() {
    this.$store.dispatch("travel_list/getAllTravelLists", 1);
  },
  methods: {
    submit() {
      const _self = this;
      this.emergency.travel_list_id = this.onGoingTours[0].id;
      this.emergency.report_type = this.report_type;
      api.add_emergency_reports(_self.emergency).then(
        (res) => {
          if (res.data.status === 200) {
            Toast("提交成功");
            _self.$router.push(`/events/main`);
          }
        },
        (err) => {
          console.log(err);
          Toast("提交失败");
        }
      );
    },
    onTypeSelect(i) {
      this.type_name = i.type_name;
      this.report_type = i.id;
      this.showTypes = false;
    },
    onClickBack() {
      this.$router.go(-1);
    },
    onFormUpdate(data) {
      this.emergency.report_content = data.report_content;
      this.emergency.attachment_url = data.attachment_url;
    },
  },
};
</script>

<style>
.action_btn {
  position: fixed;
  bottom: 10px;
  border-radius: 25px;
  width: 95%;
  height: 50px;
  left: 2.5%;
  text-align: center;
}
.action_bg {
  position: fixed;
  bottom: 0px;
  height: 70px;
  width: 100%;
  left: 0px;
  text-align: center;
  background-color: white;
}
.select-container {
  height: 100%;
  background: #fff;
}
.select-item {
  font-size: 14px;
  color: #333333;
  font-weight: bold;
  text-align: center;
  height: 0.8rem;
  line-height: 0.8rem;
}
.select-item:nth-child(even) {
  background: #f4f3f3;
}
.select-item:nth-child(odd) {
  background: #fff;
}
.select-title {
  font-size: 0.3rem;
  text-align: center;
  font-weight: bold;
  height: 0.8rem;
  line-height: 0.8rem;
}
</style>